<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'App',
  methods: {
    ...mapActions(['checkDailySubscription'])
  },
  mounted() {
    // Kontrola každou hodinu
    setInterval(() => {
      this.checkDailySubscription();
    }, 3600000); // 3600000 ms = 1 hodina
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

#app {
  min-height: 100vh;
  background: linear-gradient(to right, #ff69b4, #ff8c00);
}
</style>