import { createStore } from 'vuex'
import { auth, db, firestore } from '@/firebase/init'
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth'
import { ref, get } from 'firebase/database'
import { collection, query, where, getDocs } from 'firebase/firestore';


export default createStore({
  state: {
    user: null,
    isPremium: false,
    currentCategory: null,
    currentSubcategory: null,
    confirmationResult: null,
    subscriptionMessage: null,
    unlockedCategories: [],
    questions: [],
    lastSubscriptionCheck: null,
    usedQuestions: []
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setCurrentCategory(state, category) {
      state.currentCategory = category
    },
    setCurrentSubcategory(state, subcategory) {
      state.currentSubcategory = subcategory
    },
    setQuestions(state, questions) {
      state.questions = questions
    },
    setConfirmationResult(state, result) {
      state.confirmationResult = result
    },
    addUsedQuestion(state, questionId) {
      state.usedQuestions.push(questionId)
    },
    clearUsedQuestions(state) {
      state.usedQuestions = []
    },
    setSubscriptionMessage(state, message) {
      state.subscriptionMessage = message;
    },
    setUnlockedCategories(state, categories) {
      state.unlockedCategories = categories;
    },
    setPremiumStatus(state, isPremium) {
      state.isPremium = isPremium
    },
    setLastSubscriptionCheck(state, timestamp) {
      state.lastSubscriptionCheck = timestamp;
    }
  },
  actions: {
    initializePhoneAuth() {
      console.log("Initializing phone auth...");
      if (!window.recaptchaVerifier) {
        console.log("Creating new RecaptchaVerifier...");
        try {
          window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'invisible',
            'callback': () => {
              console.log("reCAPTCHA callback executed");
            }
          });
          console.log("RecaptchaVerifier created successfully");
        } catch (error) {
          console.error("Error creating RecaptchaVerifier:", error);
          throw error;
        }
      } else {
        console.log("RecaptchaVerifier already exists");
      }
    },    
    async requestPhoneAuth({ dispatch, commit }, phoneNumber) {
      console.log("Requesting phone auth for number:", phoneNumber);
      try {
        if (!window.recaptchaVerifier) {
          console.log("RecaptchaVerifier not found, initializing...");
          await dispatch('initializePhoneAuth');
        }
        const appVerifier = window.recaptchaVerifier;
        console.log("Calling signInWithPhoneNumber...");
        const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        console.log("Confirmation result received:", confirmationResult);
        commit('setConfirmationResult', confirmationResult)
        return true; // Přidáme návratovou hodnotu pro úspěšné odeslání
      } catch (error) {
        console.error("Detailed error in requestPhoneAuth:", error);
        throw error
      }
    },    
    async logout({ commit }) {
      try {
        await auth.signOut()
        commit('setUser', null)
        commit('setPremiumStatus', false)
      } catch (error) {
        console.error("Chyba při odhlašování:", error)
        throw error
      }
    },
    async verifyPhoneCode({ commit, dispatch, state }, { code, fullPhoneNumber }) {
      try {
        console.log("Verifying phone code...");
        const result = await state.confirmationResult.confirm(code)
        console.log("Phone code verified successfully");
        const user = result.user
        console.log("User:", user);
        commit('setUser', user)
        console.log("Checking subscription status...");
        await dispatch('checkSubscriptionStatus', fullPhoneNumber)
      } catch (error) {
        console.error("Detailed error in verifyPhoneCode:", error)
        throw error
      }
    },
    async checkSubscriptionStatus({ commit }, phoneNumber) {
      console.log("Checking subscription status for phone:", phoneNumber);
      try {
        const userRef = ref(db, `users/${phoneNumber}`);
        const snapshot = await get(userRef);
        
        if (snapshot.exists()) {
          const userData = snapshot.val();
          let unlockedCategories = [];
          let subscriptionMessage = '';
          let isPremium = false;
          
          // Kontrola dočasného přístupu (Denní Pass)
          if (userData.temporaryAccess) {
            const { startTime, duration } = userData.temporaryAccess;
            const endTime = startTime + duration;
            if (Date.now() / 1000 < endTime) {
              isPremium = true;
              unlockedCategories = ['Páry', 'Sexuální', 'Kontroverzní', 'Mix'];
              subscriptionMessage = `Máte aktivní Denní Pass platný do ${new Date(endTime * 1000).toLocaleString()}.`;
            }
          }
          
          // Pokud není aktivní dočasný přístup, zkontrolujeme dlouhodobé předplatné
          if (!isPremium && userData.personalInfo && userData.personalInfo.isCustomer) {
            const currentProduct = userData.personalInfo.currentProductName;
            const subscriptionEnd = userData.subscription.currentPeriodEnd;
            
            if (subscriptionEnd && subscriptionEnd !== '-' && new Date(subscriptionEnd * 1000) > new Date()) {
              isPremium = true;
              
              // Logika pro určení odemčených kategorií
              switch (currentProduct) {
                case 'Silver Pass - Nikdy jsem (Páry)':
                  unlockedCategories = ['Páry'];
                  break;
                case 'Silver Pass - Nikdy jsem (Sexuální)':
                  unlockedCategories = ['Sexuální'];
                  break;
                case 'Silver Pass - Nikdy jsem (Kontroverzní)':
                  unlockedCategories = ['Kontroverzní'];
                  break;
                case 'Silver Pass - Nikdy jsem (Mix)':
                  unlockedCategories = ['Mix'];
                  break;
                case 'Gold Pass - Nikdy jsem':
                case 'Diamond Pass':
                case 'Ultimate All Pass':
                  unlockedCategories = ['Páry', 'Sexuální', 'Kontroverzní', 'Mix'];
                  break;
              }
              
              subscriptionMessage = `Máte aktivní předplatné ${currentProduct} platné do ${new Date(subscriptionEnd * 1000).toLocaleDateString()}.`;
            }
          }
          
          if (!isPremium) {
            subscriptionMessage = 'Nemáte žádné aktivní předplatné. Zvažte zakoupení prémiového přístupu pro odemknutí dalších kategorií.';
          }
          
          commit('setUnlockedCategories', unlockedCategories);
          commit('setPremiumStatus', isPremium);
          commit('setSubscriptionMessage', subscriptionMessage);
          commit('setLastSubscriptionCheck', Date.now());
          
          // Přidáme historii předplatných
          if (userData.subscriptionHistory) {
            commit('setSubscriptionHistory', userData.subscriptionHistory);
          }
        } else {
          console.log("No data found for this user");
          commit('setUnlockedCategories', []);
          commit('setPremiumStatus', false);
          commit('setSubscriptionMessage', 'Nemáte žádné předplatné k Vašemu telefonnímu účtu. Pokud je to chyba, kontaktujte nás.');
        }
      } catch (error) {
        console.error("Error checking subscription status:", error);
        throw error;
      }
    },
    
    async checkSubscriptionIfNeeded({ dispatch, state }) {
      const now = Date.now();
      if (!state.lastSubscriptionCheck || now - state.lastSubscriptionCheck > 5 * 60 * 1000) { // 5 minut
        if (state.user && state.user.phoneNumber) {
          await dispatch('checkSubscriptionStatus', state.user.phoneNumber);
        }
      }
    },

    async loadQuestions({ commit }, category) {
      try {
        const categoryString = Array.isArray(category) ? category[0] : category;
        console.log("Loading questions for category:", categoryString);
        const questionsRef = collection(firestore, 'questions');
        const q = query(questionsRef, 
          where('type', '==', 'nikdyjsem'),
          where('category', 'array-contains', categoryString)
        );
        console.log("Query:", q);
        const querySnapshot = await getDocs(q);
        
        console.log("QuerySnapshot size:", querySnapshot.size);
        console.log("QuerySnapshot empty:", querySnapshot.empty);
        
        const questions = [];
        querySnapshot.forEach((doc) => {
          console.log("Document data:", doc.data());
          questions.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        console.log("Loaded questions:", questions);
        commit('setQuestions', questions);
        commit('setCurrentCategory', categoryString);
      } catch (error) {
        console.error("Error loading questions:", error);
        throw error;
      }
    },

    getRandomQuestion({ state, commit }) {
      console.log(`Getting random question`);
      console.log(`Total questions: ${state.questions.length}`);
      const availableQuestions = state.questions.filter(q => 
        !state.usedQuestions.includes(q.id)
      );
      console.log(`Available questions: ${availableQuestions.length}`);
      if (availableQuestions.length === 0) {
        return null;
      }
      const randomIndex = Math.floor(Math.random() * availableQuestions.length);
      const question = availableQuestions[randomIndex];
      commit('addUsedQuestion', question.id);
      return question.text;
    },
    
    refreshQuestions({ commit, dispatch, state }) {
      commit('clearUsedQuestions');
      if (state.currentCategory) {
        return dispatch('loadQuestions', state.currentCategory);
      }
    }
  },
  
  getters: {
    isLoggedIn: state => !!state.user,
    currentUser: state => state.user,
    isPremium: state => state.isPremium,
    currentCategory: state => state.currentCategory,
    currentSubcategory: state => state.currentSubcategory,
    questions: state => state.questions,
    truthsCount: state => state.questions.filter(q => q.type === 'pravda').length,
    daresCount: state => state.questions.filter(q => q.type === 'ukol').length,
    remainingQuestionsCount: (state) => {
      return state.questions.filter(q => !state.usedQuestions.includes(q.id)).length;
    },
    unlockedCategories: state => state.unlockedCategories,
    subscriptionMessage: state => state.subscriptionMessage
  }
})