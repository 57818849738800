<template>
  <div class="settings-modal rubik-font" v-if="show">
    <div class="settings-content">
      <h3>Nastavení</h3>
      <div class="language-selection">
        <h4>Jazyk:</h4>
        <button @click="changeLanguage('cz')" :class="{ active: currentLanguage === 'cz' }">CZ</button>
        <button @click="showLanguageAlert" :class="{ disabled: true }">SK</button>
        <button @click="showLanguageAlert" :class="{ disabled: true }">EN</button>
      </div>
      <a href="https://chlastaci-hry.cz/predplatne/" target="_blank" class="settings-link">Zakoupit předplatné</a>
      <a href="https://chlastaci-hry.cz/my-account/" target="_blank" class="settings-link">Registrovat se</a>
      <a href="https://chlastaci-hry.cz/my-account" target="_blank" class="settings-link">Můj účet</a>
      <a href="mailto:info@chlastaci-hry.cz" class="settings-link">Kontaktovat vývojáře</a>
      <button @click="$emit('close')" class="close-button">Zavřít</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsModal',
  props: {
    show: Boolean
  },
  data() {
    return {
      currentLanguage: 'cz'
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = lang
      // Zde implementujte logiku pro změnu jazyka v aplikaci
    },
    showLanguageAlert() {
      alert("Jazyková podpora již brzy.")
    },
    handleLogin() {
      this.$emit('close')
      setTimeout(() => {
        this.$emit('showLogin')
      }, 300) // Malé zpoždění pro plynulý přechod
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.rubik-font {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

</style>

  <style scoped>.settings-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .settings-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 280px;
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  h3, h4 {
    color: #333;
    margin-top: 0;
    text-align: center;
  }
  
  .language-selection {
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }
  
  .language-selection button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ddd;
    background-color: white;
    cursor: pointer;
    font-size: 16px;
  }
  
  .language-selection button.active {
    background-color: #ff69b4;
    color: white;
  }
  
  .settings-button, .close-button, .settings-link {
    display: block;
    width: 200px;
    margin: 10px 0;
    padding: 10px;
    background-color: #ff69b4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    transition: background-color 0.3s;
    box-sizing: border-box;
  }
  
  .settings-link {
    appearance: button;
    -webkit-appearance: button;
    -moz-appearance: button;
  }
  
  .settings-button:hover, .close-button:hover, .settings-link:hover {
    background-color: #ff8c00;
  }
  
  .close-button {
    background-color: #ddd;
    color: #333;
  }
  
  .close-button:hover {
    background-color: #ccc;
  }

  .language-selection button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  </style>