<template>
  <div class="game-view rubik-font">
    <div class="header">
      <img src="@/assets/Graphics/back-arrow.png" alt="Back" class="back-button" @click="$router.push('/')">
      <img src="@/assets/Graphics/logo.png" alt="Logo" class="logo">
      <img src="@/assets/Graphics/settings-icon.png" alt="Settings" class="settings-icon" @click="toggleSettings">
    </div>
    <div class="content">
      <div class="question-card">
        <div class="category-bar">
          <h2>Kategorie {{ currentCategory }}</h2>
        </div>
        <p>{{ currentText }}</p>
      </div>
      <div class="action-buttons">
        <button @click="nextQuestion">Další otázka</button>
      </div>
    </div>
    <div class="footer">
      <div class="social-icons">
        <a href="https://x.com/chlastaci_hry" target="_blank">
          <img src="@/assets/Graphics/twitter-icon.png" alt="Twitter">
        </a>
        <a href="https://www.instagram.com/chlastaci.hry/" target="_blank">
          <img src="@/assets/Graphics/instagram-icon.png" alt="Instagram">
        </a>
        <a href="https://www.tiktok.com/@chlastaci.hry" target="_blank">
          <img src="@/assets/Graphics/tiktok-icon.png" alt="TikTok">
        </a>
      </div>
      <div class="copyright">© Created by Dominik.</div>
    </div>
    
    <div v-if="showRefreshDialog" class="overlay">
      <div class="refresh-dialog">
        <p>Všechny otázky byly vyčerpány. Chcete je znovu doplnit?</p>
        <button @click="refreshQuestions">Ano</button>
        <button @click="closeRefreshDialog">Ne</button>
      </div>
    </div>

    <SettingsModal 
      :show="showSettings" 
      @close="toggleSettings"
      @showLogin="showLoginForm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SettingsModal from './SettingsModal.vue'

export default {
  name: 'GameView',
  components: {
    SettingsModal
  },
  data() {
    return {
      currentText: '',
      showRefreshDialog: false,
      showSettings: false,
      lastType: '',
      startingSentences: [
        "Nejchytřejší hráč začíná.",
        "Ten s nejvyšším bodycountem začíná.",
        "Ten s nejnižším bodycountem začíná.",
        "Vlastník tohoto telefonu/zařízení začíná.",
        "Nejstarší osoba začíná.",
        "Nejmladší hráč začíná.",
        "Skřet začíná! (Ten nejnižší hráč)",
        "Poslední, kdo přišel, začíná.",
        "Ten, kdo měl sex jako poslední, začíná.",
        "Ten, kdo má nejdelší vlasy, začíná.",
        "Ten, kdo ho má nejdelšího začíná! :D",
        "Ta, co má největší cecky, začíná! :D",
        "Ten, kdo má na sociálních sítích nejvíce sledujících, začíná."
      ],
      gameStarted: false
    }
  },
  computed: {
    ...mapGetters(['currentCategory', 'remainingQuestionsCount'])
  },
  methods: {
    ...mapActions(['getRandomQuestion', 'refreshQuestions']),
    async nextQuestion() {
      if (!this.gameStarted) {
        this.gameStarted = true;
      }
      const question = await this.getRandomQuestion();
      if (question) {
        this.currentText = question;
      } else {
        this.showRefreshDialog = true;
      }
    },
    async refreshQuestions() {
      try {
        await this.refreshQuestions();
        this.showRefreshDialog = false;
        await this.nextQuestion();
      } catch (error) {
        console.error("Error refreshing questions:", error);
        alert("Došlo k chybě při obnovování otázek. Prosím, zkuste to znovu.");
      }
    },
    closeRefreshDialog() {
      this.showRefreshDialog = false
      this.currentText = `Žádné další otázky`
    },
    toggleSettings() {
      this.showSettings = !this.showSettings
    },
    showLoginForm() {
      console.log("Show login form")
    },
    getRandomStartingSentence() {
      const randomIndex = Math.floor(Math.random() * this.startingSentences.length)
      return this.startingSentences[randomIndex]
    }
  },
  mounted() {
  if (!this.currentCategory) {
    alert('Kategorie nebyla vybrána po restartu stránky. Budete přesměrováni na výběr kategorie.');
    this.$router.push('/');
  } else {
    this.currentText = this.getRandomStartingSentence();
  }
}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.rubik-font {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

</style>

<style scoped>
.game-view {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #944CD1;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  padding-left: 5%;
  padding-right: 5%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 40px;
}
.back-button, .settings-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.logo {
  height: 60px;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.question-card {
  background-color: white;
  border-radius: 20px;
  width: 80%;
  max-width: 1000px;
  min-height: 500px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.category-bar {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}
.category-bar h2 {
  margin: 0;
  font-size: 20px;
  color: #888;
}
.question-card p {
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
  color: black;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  width: 80%;
  max-width: 1000px;
}
.action-buttons button {
  padding: 22px 70px;
  font-size: 20px;
  font-weight: bold;
  border: none;
  border-radius: 15px;
  background-color: black;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.action-buttons button:hover {
  transform: scale(1.07);
  background-color: darkviolet;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.social-icons a img {
  padding-right: 10px;
  height: 30px;
}

.social-icons img:last-child {
  margin-right: 0;
}
.copyright {
  font-size: 14px;
  font-weight: bold;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.refresh-dialog {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  text-align: center;
}
.refresh-dialog p {
  color: black;
  margin-bottom: 20px;
  line-height: 25px;
}
.refresh-dialog button {
  margin: 0 10px;
  padding: 10px 50px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  font-size: 16px;
}
.refresh-dialog button:last-child {
  background-color: #f44336;
}

/* Mobile responzive */
@media (max-width: 650px) {
  .header {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .back-button, .user-icon, .settings-icon {
    height: 30px;
    width: 30px;
  }
  .logo {
    height: 45px;
  }
  .social-icons a img {
    padding-right: 8px;
    height: 25px;
  }
  .question-card {
    width: 100%;
    min-height: 350px;
  }
  .action-buttons {
    width: 100%;
    gap: 10px;
    margin-top: 10px;
  }
  .action-buttons button {
    width: 100%;
    height: 60px;
    padding: 0;
  }
  .refresh-dialog p {
    line-height: 30px;
    font-size: 18px;
  }
  .refresh-dialog button {
    margin: 5px 10px;
    padding: 10px 50px;
    width: 150px;
    font-size: 23px;
  }
}

</style>