import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  // Zde vložte vaši Firebase konfiguraci
  apiKey: "AIzaSyDnoHyNvs7t7Ssbum9pDERGBiOSKXT_IdU",
  authDomain: "chlastacihry-cz03.firebaseapp.com",
  databaseURL: "https://chlastacihry-cz03-default-rtdb.firebaseio.com",
  projectId: "chlastacihry-cz03",
  storageBucket: "chlastacihry-cz03.appspot.com",
  messagingSenderId: "839557965734",
  appId: "1:839557965734:web:ea808ed3509a9958e01167",
  measurementId: "G-Y4482XQJJE"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);
const firestore = getFirestore(app);

export { auth, db, firestore };