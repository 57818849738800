import { createRouter, createWebHistory } from 'vue-router'
import CategorySelection from '@/views/CategorySelection.vue'
import Game from '@/views/Game.vue'

const routes = [
  {
    path: '/',
    name: 'CategorySelection',
    component: CategorySelection
  },
  {
    path: '/game/:category',
    name: 'Game',
    component: Game,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router